/* ----- CSS ----- */

// Project CSS
require('@fortawesome/fontawesome-free/css/all.min.css');
require ('swiper/swiper-bundle.css');
require('../css/normalize.css');
require('../css/main.css');
require('../css/pns.css');
require('../css/rd.css');
import '../lib-ext/colorbox/colorbox.css';
import '../lib-ext/fontawesome/css/all.css';
import '../lib-ext/chosen/chosen.min.css';
import '../lib-ext/slick/slick.css';
import '../lib-ext/toastr/toastr.min.css';
import '../lib-ext/cookieconsent/cookieconsent.min.css';



/* ----- Javascript ----- */

const $ = require('jquery');
global.$ = global.jQuery = $;
require('jquery-ui');
require('jquery-sticky');
require('lozad');

// Project JS
import '../lib-ext/colorbox/jquery.colorbox-min.js';
import '../lib-ext/modernizr/modernizr.js';
import '../lib-ext/chosen/chosen.jquery.min.js';
import '../lib-ext/slick/slick.js';
import '../lib-ext/parallax/parallax.js';
import '../lib-ext/toastr/toastr.min.js';
import '../lib-ext/cookieconsent/cookieconsent.min.js';
import './pns.js';

