// Javascript principal

$().ready(function () {

    // ---------------------------------------------------------------------------------------------------- //
    // Change couleur lettres P,N,S
    // ---------------------------------------------------------------------------------------------------- //
    $('.section-title h1, .section-title h2, #contenu_page h2').each(function () {
        let letter = ["P", "N", "S", "p", "n", "s"];
        let res = "", cls = "";
        let t = $(this).text();

        for (let i = 0; i < t.length; i++) {
            for (let j = 0; j < letter.length; j++) {
                if (t[i] == letter[j]) {
                    cls = "rouge";
                }
            }
            res += "<span class='" + cls + "'>" + t[i] + "</span>";
            cls = "";
        }
        $(this).html(res);
    });


    // ---------------------------------------------------------------------------------------------------- //
    // Sticky menu
    // ---------------------------------------------------------------------------------------------------- //
    $("header").sticky({topSpacing: 0, zIndex: 200});

    if( $(window).width() < 970 ){
        $("header").unstick();

        $(window).scroll(function() {
            if($(window).scrollTop() + $(window).height() == $(document).height()){
                $("aside").fadeIn(200);
            } else if ($(window).scrollTop() > 100) {
                $("aside").fadeOut(200);
            } else if ($(document).scrollTop() === 0){
                $("aside").fadeIn(200);
            }
        });
    }

    $(document).scroll(function () {
        if ($(document).scrollTop() > 0) {
            $("header").addClass("scrollingdown");
        } else {
            $("header").removeClass("scrollingdown");
        }
    });

    // Aside

    $("aside > ul > li").on('click', function () {
        if ($(this).hasClass('open')) {
            $("aside > ul > li").removeClass('open')
            $("aside > ul > li ul").slideUp()
        }
        else {
            $("aside > ul > li").removeClass('open')
            $("aside > ul > li ul").slideUp()
            $(this).find('ul').slideDown()
            $(this).toggleClass('open');
        }
    })


    // Parallaxe
    $('#banniere').parallax({
        imageSrc: '/medias/img/banniere.jpg'
    });
    $('#statistiques').parallax({
        imageSrc: '/medias/img/banniere-2.jpg'
    });
    $('#banniere-accompagnement').parallax({
        imageSrc: '/medias/img/banniere-3.jpg'
    });
    $('#banniere-candidat').parallax({
        imageSrc: '/medias/img/banniere-5.jpg'
    });
    $('#accompagnement').parallax({
        imageSrc: '/medias/img/banniere.jpg'
    });
    $('#info').parallax({
        imageSrc: '/medias/img/banniere-4.jpg'
    });


    // ---------------------------------------------------------------------------------------------------- //
    // Menu mobile
    // ---------------------------------------------------------------------------------------------------- //
    var rotate = 90;
    $('#burger_menu').click(function(){
        $(this).next('nav').slideToggle();
        $(this).find('i').css('transform','rotate(' + rotate + 'deg)');
        rotate += 90;
    });


    // ---------------------------------------------------------------------------------------------------- //
    // Slider
    // ---------------------------------------------------------------------------------------------------- //
    $('.charte__liste').slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 2,
        appendArrows: $(".charte__arrow"),
        prevArrow: "<a href='#' class='prevarrow arrow'><img src='/img/icone/chevron-left.svg' alt='Précédent'></a>",
        nextArrow: "<a href='#' class='nextarrow arrow'><img src='/img/icone/chevron-right.svg' alt='Suivant'></a>",
        responsive: [
            {
                breakpoint: 1281,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 959,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 770,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]

    });

    $('#slider-offre').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        vertical: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: true,
        customPaging: function(slider, i) {
            return '<span class="dot">'+(i+1)+'</span>';
        },
        responsive: [
            {
                breakpoint: 959,
                settings: {
                    vertical: false,
                    dots:false
                }
            },
        ]
    })


    // ---------------------------------------------------------------------------------------------------- //
    // Animation agence
    // ---------------------------------------------------------------------------------------------------- //
    $('.liste_agence--item').hover(function () {
        $(this).toggleClass('grow');
    });


    // ---------------------------------------------------------------------------------------------------- //
    // Popup connexion
    // --------------------------------------------------------------------------------------------------- //
    $('#openModalLogin').colorbox({
        maxWidth: '100%',
        maxHeight: '100%',
        innerWidth: '500px',
        fixed: true,
        href: Routing.generate('login')
    });

    // Il y a un bout de script avec la vue (templates/security/desktop/login.html.twig)
    $(document).on('click', '#login_submit_button', function (event) {
        // Je désactive les comportement par defaut, dont la validation html5 des champs (required,...)
        event.preventDefault();
        // Je vide les erreurs du back
        $('#form_login_error').empty();

        // Je regarde si le formulaire à des erreurs et si oui, je déclanche le submit, comme ça, le navigateur va
        // afficher les erreurs en natif tout bien.
        var form = $( "#form_login" );
        if (form[0].checkValidity() === false) {
            $('#login_submit').click();

            return false; // et je sort histoire de pas executer la suite
        }

        // Si pas d'erreur front, let go
        //todo gif loader durant la soumission
        $.ajax({
            url: Routing.generate('login_check'),
            type: "POST",
            data: {
                _csrf_token: $('#_csrf_token_login').val(),
                email: $('#inputEmail').val(),
                password: $('#inputPassword').val()
            },
            complete: function (data, statut) {
                if (statut === 'success') {
                    window.location.replace(data.responseJSON.redirectTo);
                }
            },
            error: function (resultat, statut, error) {
                $('#form_login_error').text(resultat.responseJSON.message);
            }
        });
    });


    // ---------------------------------------------------------------------------------------------------- //
    // Selects à choix multiples
    // ---------------------------------------------------------------------------------------------------- //
    $("#create_offer_department").chosen();
    $("#create_offer_categories").chosen();
    $("#create_offer_tags").chosen();
    $("#type_contrat").chosen();
    $("#categorie").chosen();
    $("#search_offer_department").chosen();
    $("#search_offer_categories").chosen();


    // ---------------------------------------------------------------------------------------------------- //
    // Relevés d'heures
    // ---------------------------------------------------------------------------------------------------- //
    /* Affiche l'intitulé de la colonne en mobile */
    if( $(window).width() < 960 || $(window).resize() ) {
        rdTable();
    }

    function rdTable(){
        $('.table-responsive .ligne_head div:not(.no_loop)').each(function (index) {
            let value = $(this).text();
            var index = index + 1;
            $('.row.ligne:not(.table-secondary) div:nth-of-type(' + index + ')').attr('data-before', value);
            $('.row.ligne:not(.table-secondary) div:nth-of-type(' + index + '):not(.clear):empty').text('-');
        });
    }


    // ---------------------------------------------------------------------------------------------------- //
    // Smooth Scroll
    // ---------------------------------------------------------------------------------------------------- //
    $(function() {
        $(document).on('click', 'a[href*="#"]:not([href="#"])', function() {
            if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
                if (target.length) {
                    $('html, body').animate({
                        scrollTop: target.offset().top - 50
                    }, 1000);
                    return false;
                }
            }
        });
    });

    // ---------------------------------------------------------------------------------------------------- //
    // Popup de mise en avant
    // ---------------------------------------------------------------------------------------------------- //
    if($('#popup-info').length > 0) {
        let open = window.sessionStorage.getItem('open');
        if (open === null) {
            /*Ouverture de la popup*/
            $('#popup-info').fadeIn().css('display', 'grid');
            window.sessionStorage.setItem('open', '1');
            $('body').css('overflow', 'hidden');

            /*Fermeture de la popup*/
            $(document).mouseup(function (e) {
                let container = $('.popup-content');
                if (!container.is(e.target) && container.has(e.target).length === 0) {
                    popupReact();
                }
            });
            $('.popup-content .popup-close').on('click', function () {
                popupReact();
            });
            $(document).on('keydown', function (e) {
                if (e.which === 27) {
                    popupReact();
                }

            });

            function popupReact() {
                $('#popup-info').fadeOut()
                $('body').css('overflow', 'auto');
                window.sessionStorage.setItem('open', '1');
            }
        }
        ;
    }
});

import lozad from 'lozad';

document.addEventListener("DOMContentLoaded", function () {
    const observer = lozad(".lozad"); // les éléments avec la classe 'lozad'
    observer.observe();
});

